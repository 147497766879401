import Background  from '../components/background';
import backgro from '../img/background.jpg' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../styles/theme.css';
const Contact = () => {
    return(
        <>
            <div>
                <div>
                    <img src={backgro} className='backgroundcontact'/>
                    <h1 className='contacttitle'>Contact</h1>
                </div>
                
                
                <div className="contact">
                    <div className="contactone">
                        <FontAwesomeIcon icon={faPhone} />
                        <h2>832-998-6437</h2>
                        <h3>management@dusteeaway.com</h3>
                        <p>Inglish Contact</p>
                    </div>
                    <div className="contactwo">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <h2>346-443-0189</h2>
                        <h3>management@dusteeaway.com</h3>
                        <p>Spanish Contact</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact