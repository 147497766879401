import Footer from './components/footer';
import Planes from './components/planes';
import Info from './components/infocompany';
import Header from './components/header';
const Home = () => {
    return(
        <>
            <header>
                <div>
                    <Header />
                </div>
            </header>
            <Info/>
            <Planes/>
        </>
    )
}
export default Home;