import React, { Component } from 'react';
import ReactDom from 'react-dom/client';
import Header from './components/header';
// import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Footer from './components/footer';
import Planes from './components/planes';
import Info from './components/infocompany';
import './styles/theme.css';
import Navbar from './components/navbar';

// import { BrowserRouter,Routes,Route } from "react-router-dom";
// import { BrowserRouter,Routes,Route,Link } from "react-router-dom";

class App extends Component{
    
    state={
        buttonnav:true
    }
    buttonNav2 = () => {
        this.setState({
            buttonnav:this.state.buttonnav ? false:true
        })
    }
    render(){
        return(
            <>  
               

                {/* <BrowserRouter>
                    <Navbar/>
                    <Routes>
                        <Route>
                            <Route path="/planes" element={<Planes />}/>
                            <Route path="/info" element={<Info />}/>
                        </Route>
                    </Routes>
                </BrowserRouter> */}
                
                <Navbar button={this.state.buttonnav} buttonfunction={this.buttonNav2}/>
                
                <Footer/>    
            </>
        )
    }
}   

const root = ReactDom.createRoot(document.getElementById('root'));
root.render(<App/>);
