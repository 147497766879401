import React, { Component } from "react";
// import ReactDOM from "react-dom/client";

import Background from "./background";
// import Navbar from "./navbar";
import Mission from "./mision";


class  Header extends Component {
    // const news = props.img
    render(){
        return(
            <>  
                {/* <Navbar/> */}
                <Mission/>
                <Background/>
            </>
        )
    }
}

export default Header