import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const Planes = () => {
    return(
        <>
            <div className="package">
                <div className="package1">
                    {/* <div className="price">
                        <h2>$29</h2>
                        <p>/month</p>  
                    </div> */}
                    <h3>Standar Cleaning</h3>
                    <h4>Regular cleaning</h4>
                    <div className="duties">
                        <p>Studio : $60</p>
                        <p>1 bed 1 bath : $60 – $90</p>
                        <p>2 bed 1 bath : $90 – $120</p>
                        <p>2 bed 2 bath : $95 – $130</p>
                        <p>3 bed 1 bath : $110 – $165</p>
                        <p>3 bed 2 bath : $135 – $180</p>
                        <p>4 bed 3 bath : $140 – $200</p>
                        <p>Additional Rooms : $10 – $30</p>
                    </div>

                    <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
                <div className="package2">
                    {/* <div className="price">
                        <h2>$29</h2>
                        <p>/month</p>  
                    </div> */}
                    <h3>Deep Cleaning</h3>
                    <h4>This is a complete cleaning</h4>
                    <div className="duties">
                        <p>Studio : $90</p>
                        <p>1 bed 1 bath : $90 – $110</p>
                        <p>2 bed 1 bath : $120 – $175</p>
                        <p>2 bed 2 bath : $135 – $195</p>
                        <p>3 bed 1 bath : $150 – $250</p>
                        <p>3 bed 2 bath : $180 – $280</p>
                        <p>4 bed 3 bath : $200 – $400</p>
                        <p>Additional Rooms : $35 - 45</p>
                    </div>

                    <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
                <div className="package3">
                    {/* <div className="price">
                        <h2>$29</h2>
                        <p>/month</p>  
                    </div> */}
                    <h3>Move-Out Cleaning</h3>
                    <h4>This is different cleaning</h4>
                    <div className="duties">
                        <p>Studio : $100</p>
                        <p>1 bed 1 bath : $100 – $120</p>
                        <p>2 bed 1 bath : $120 – $180</p>
                        <p>2 bed 2 bath : $135 – $210</p>
                        <p>3 bed 1 bath : $175 – $260</p>
                        <p>3 bed 2 bath : $180 – $300</p>
                        <p>4 bed 3 bath : $200 – $390</p>
                        <p>Additional Rooms : $35 - 45</p>
                    </div>
                    

                    <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
            </div>
        </>
    )
}


export default Planes;