import React, { Component } from "react";

class Mission extends Component{
    state={
        active:true
    }
    color(){
        setTimeout(() => {
            this.setState({
                active:this.state.active ? false:true
            })
        }, 9000);
    }
    render(){
        return (
            <>
                {this.color()}
                {/* <div className="mission" style={{display:`${this.state.active ? 'block':'none'}`}}> */}
                <div className={`${this.state.active ? "mission2":"mission"}`}>
                    <div className="mission4">
                        <div>
                            <h2>Our mission</h2>
                        </div>
                        <div className="info">
                            <p>
                                Our mission is to create a clean and pleasant home environment for our clients, understanding every need and providing the best cleaning service, with the highest level of integrity, honesty, and professionalism.
                            </p>
                        </div>
                    </div>
                    {/* <button onClick={()=>this.color()}>ChangeColor</button> */}
                </div>
                <div className={`${this.state.active ? "mission":"mission2"}`}>
                    <div className="mission4">
                        <div>
                            <h2>Our vision</h2>
                        </div>
                        <div className="info">
                            <p>
                            Our plan for 2025 is to expand our cleaning services (in/out), offering a complete package that meets the needs and expectations of our clients.
                            </p>
                        </div>
                    </div>
                    {/* <button onClick={()=>this.color()}>ChangeColor</button> */}
                </div>
            </>
        )
    }
}

export default Mission;