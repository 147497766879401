import personCleaning from '../img/personcleaning.png' 
import Background from '../components/background';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import '../styles/icons.css'
const Info = () => {
    return(
        <>
            <div className='infoCompany'>
                
                <div className='ima'>
                    <img src={personCleaning} className='image'></img>
                </div>
                <div className='info'>
                    <div className='title'>
                        <FontAwesomeIcon className='icon' icon={faCircleCheck} />
                        <p>Our company will provide the best cleaning service to our clients with honesty, kindness, and complete satisfaction.</p>
                    </div>
                    <div className='information1'> 
                        <FontAwesomeIcon className='icon' icon={faCircleCheck} />  
                        <p>Our mission is to create a clean and pleasant home environment for our clients, understanding every need and providing the best cleaning service, with the highest level of integrity, honesty, and professionalism.</p>  
                    </div>
                    <div className='information2'>
                        <FontAwesomeIcon className='icon' icon={faCircleCheck} />
                        <p>Our plan for 2025 is to expand our cleaning services (in/out), offering a complete package that meets the needs and expectations of our clients.</p>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Info