// import  ReactDOM from "react-dom/client";

// import Info from "./infocompany";
// import App from '../index';
// import {Link } from "react-router-dom";
import React from "react";
import { BrowserRouter,Routes,Route,Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';


import Planes from "./planes";
import Info from "./infocompany";
import Home from "../home";
import Contact from "./contac";
import '../styles/theme.css';
const Navbar = (props) => {
    // console.log(props.button)
    
    return(
        <BrowserRouter>
            <div className="navbar">
                <div className="Principal">
                    <h1 className="PrincipalTitle">Dusteeaway</h1>
                    {/* <div className="nav_toggle"> */}
                    <button onClick={props.buttonfunction }>{props.button ? <FontAwesomeIcon icon={faBars} className="icon"/>:<FontAwesomeIcon icon={faCircleXmark} className="icon"/>}</button>
                    {/* </div> */}
                </div>
                
                <div className="PrincipalNav">
                    <div className={props.button ? 'elements1':'elements2'}>
                        <Link className="a"  to='/'>Home</Link>
                        <Link className="a" to="/info">About</Link>
                        <Link className="a" to='/planes'>Package</Link>
                        <Link className="a" to='/contact'>Contact</Link>
                        <Link className="a" to='/'>Blog</Link>
                        <button>Login</button>
                    </div>
                </div>
               
            </div>
            <Routes>
                <Route>
                    <Route path="/planes" element={<Planes />}/>
                    <Route path="/info" element={<Info />}/>
                    <Route path="/contact" element={<Contact />}/>
                    <Route path="/" element={<Home />}/>
                </Route>
            </Routes>
        </BrowserRouter>    
    )
}

export default Navbar;