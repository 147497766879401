import React,{ Component, useEffect, useState } from "react";
import '../styles/backgroundslider.css';
import principalTest1 from '../img/principalTest1.jpg';
import principalTest2 from '../img/principalTest2.jpg';
import background from '../img/background.jpg';
import imageSlider from "./data";
// class Background extends Component{
//     state = {
//         currentIndex:0,
//         nextIndex:0,
//     }
//     json_images = [
//         {url:principalTest1},
//         {url:principalTest2},
//         {url:background}
//     ]
//     setIndexImage = setInterval(() => {
//         const images = this.json_images.length
//         this.setState({
//             currentIndex : this.state.currentIndex > images ?  this.state.currentIndex -1:this.state.currentIndex+=1
//         })
//     }, 2000);
//     render(){
//         return(
//             <>
//                 <div className="cover">
//                     <div style={{backgroundImage:`url(${this.json_images[this.state.currentIndex]['url']})`,width:'100%',height:'1000px'}} className="imgProfile" onChange={this.setIndexImage}/>
//                 </div>
//             </>
//         )
//     }
// }

// export default Background;

const Background = () => {
    const[currenState,setCurrentState] = useState(0)
    useEffect(()=>{
        const timer = setTimeout(()=>{
            if(currenState === imageSlider.length -1){
                setCurrentState(0)
            }else{
                setCurrentState(currenState+1)
            }
        },5000)
    })
    const bgImageStyle = {
        backgroundImage:`url(${imageSlider[currenState].url})`,
        backgroundPosition:'center',
        backgroundSize:'cover',
        height:'100%',
        // width:'100%'

    }

    return(
        <div className="countiner-style">
            <div style={bgImageStyle} className="slider"></div>
        </div>
    )
}
export default Background