import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagramSquare } from '@fortawesome/free-solid-svg-icons';
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {faInstagram,faFacebook,faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import '../styles/icons.css'
const Footer = () => {
    return(
        <>
            <div className="footer">
                <div className="InfoFooter">
                    <h2>This is an exaple</h2>
                    <p>Dusteeaway 2023</p>
                </div>
                <div className="SocialMedias">
                    
                    <FontAwesomeIcon icon={faFacebook} className='facebook'/>
                    {/* <p>Facebook</p> */}
                    {/* <FontAwesomeIcon icon={icon({name: 'coffee', style: 'solid'})} /> */}
                    <FontAwesomeIcon icon={faInstagram} className='instagram'/>
                    
                    {/* <FontAwesomeIcon icon={icon({name: 'twitter', style: 'brands'})} />  */}
                </div>
                
            </div>
            {/* <div className='whatsapp'> */}
            <FontAwesomeIcon icon={faWhatsapp}  className='whatsapp'/>
            {/* </div> */}
            
            <div className="UnderFooter">
                <p>Cleaning services</p> 
            </div>
        </>
    )
}

export default Footer;